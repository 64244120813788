import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config, server } from "../../index";
import { Alert } from "antd";

export enum PaymentReason {
  //@ts-ignore
  //eslint-disable-next-line
  fiat_listing_fee = "fiat_listing_fee",
}

const reasonMap: Record<PaymentReason, string> = {
  fiat_listing_fee: "Fiat Listing Fee",
};

const priceList: Record<PaymentReason, number> = {
  fiat_listing_fee: 0.1,
};

export const PaymentPage = () => {
  const { reason, id } = useParams();

  const [error, setError] = useState("");

  const pay = async ({
    reason,
    id,
    ammount,
  }: {
    reason: PaymentReason;
    id: string;
    ammount: number;
  }) => {
    await window?.Pi?.createPayment(
      {
        amount: Number(ammount),
        memo: `Watugot transaction for ${
          reasonMap[reason as PaymentReason]
        } for id: ${id}`,
        metadata: {
          reason,
          id,
          ammount,
        },
      },
      {
        onReadyForServerApproval: async function (paymentId: string) {
          //console.log("onReadyForServerApproval", paymentId);
          return await server.post(
            `/reasonpayment/approve/paymentId/${paymentId}`,
            {},
            config
          );
        },
        onReadyForServerCompletion: async function (
          paymentId: string,
          txid: string
        ) {
          return await server.post(
            `/reasonpayment/complete/paymentId/${paymentId}/txid/${txid}`,
            {},
            config
          );
        },
        onCancel: async function (paymentId: string) {
          //console.log("onCancel", paymentId);
          return await server.post(
            `/reasonpayment/cancel/paymentId/${paymentId}`,
            {},
            config
          );
        },
        onError: function (error: any, payment: string) {
          setError(error);
        },
      }
    );
  };

  useEffect(() => {
    try {
      //  alert("reason" + reason);
      // alert("id" + id);
      if (reason && reason in PaymentReason && id) {
        //  alert("paying");
        pay({
          reason: reason as PaymentReason,
          id,
          ammount: priceList[reason as PaymentReason],
        });
      }
    } catch (e) {
      // alert(JSON.stringify(e));
    }
  }, [reason, id]);

  if (reason && !(reason in PaymentReason)) {
    return <div>Invalid Reason</div>;
  }

  return (
    <div>
      {error && <Alert message={error} type="error" />}
      <h1>Preparing your payment</h1>
      <p>Reason: {reasonMap[reason as PaymentReason]}</p>
    </div>
  );
};
